<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      spinner="bar-fade-scale"
      color="#1976d2"
      size="128"
      is-full-screen
    />
    <v-snackbar v-model="showsnackbar" color="black" right>
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">{{ msg }}</v-flex>
        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showsnackbar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-else wrap justify-center>
      <v-flex px-5 pt-5 py-5 xs12 sm12 md12 lg12 xl12>
        <v-card class="pa-6">
          <v-card-title class="">
            Affiliator Details
            <v-spacer></v-spacer>
            <v-col cols="6" md="2" class="px-11">
              <v-btn
                outlined
                color="red"
                :to="'/affiliatorEdit?affiliateId=' + user._id"
              >
                <span class="item_title">Edit Profile</span>
              </v-btn>
            </v-col> </v-card-title
          ><br />
          <!-- <b-container v-if="report"> -->
          <div v-if="user">
            <v-layout wrap justify-center>
              <v-flex xs12 sm6 md3 lg2 align-self-center v-if="user.image">
                <div v-viewer>
                  <v-avatar
                    v-if="user.image"
                    size="150"
                    style="cursor: pointer"
                  >
                    <img
                      style="object-fit: cover"
                      :src="mediaURL + user.image"
                    />
                  </v-avatar>
                </div>
              </v-flex>
            </v-layout>

            <v-row>
              <v-col cols="12">
                <v-card color="basil" flat>
                  <v-card-text
                    ><v-row>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">First Name</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.firstname
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Last Name</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.lastname
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Phone</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.phone
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col v-if="user.whatsapp" cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Whatsapp</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.whatsapp
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Pincode</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.pincode
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">Email</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.email
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">City</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{ user.city }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">District</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.district
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">Locality</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.locality
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="4" md="5">
                            <span class="item_title">Street Address</span>
                          </v-col>
                          <v-col cols="8" md="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.streetAddress
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">User Status</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.status
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Category</span>
                          </v-col>
                          <v-col cols="7" v-if="user.affliator">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.affliator.category
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="12">
                        <v-row>
                          <v-col cols="2">
                            <span class="item_title">Area Of Interest</span>
                          </v-col>
                          <v-col cols="10" v-if="user.affliator">
                            <span class="item_value">-</span>
                            <span
                              class="item_value ma-2"
                              v-for="(item, i) in user.affliator.areaOfInterest"
                              :key="i"
                              >{{ item }},</span
                            >
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Role</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{ user.type }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col v-if="seller.facebook" cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Facebook URL</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.facebook
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col v-if="user.instagram" cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Instagram URL</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.instagram
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col v-if="user.instagram" cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Twitter URL</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.twitter
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                      <v-col v-if="user.instagram" cols="12" md="6">
                        <v-row>
                          <v-col cols="5">
                            <span class="item_title">Pinterest URL</span>
                          </v-col>
                          <v-col cols="7">
                            <span class="item_value">-</span>
                            <span class="item_value ma-2">{{
                              user.pinterest
                            }}</span>
                          </v-col>
                        </v-row>
                        <hr />
                      </v-col>
                    </v-row>
                    <v-row v-if="documents">
                      <v-col cols="12">
                        <span class="item_title" style="font-size: 17px"
                          >Document</span
                        >
                      </v-col>
                      <v-col cols="12" sm="6" md="4" lg="4" xl="4">
                        <!-- <div
                          v-viewer
                          style="display: flex"
                          class="justify:start"
                        >
                          <img
                            style="
                              width: 100%;
                              object-fit: contain;
                              cursor: pointer;
                            "
                            :src="mediaURL + documents"
                            height="200px"
                          />
                        </div> -->
                        <v-chip class="ma-1" color="red" text-color="white">
                          <span v-if="documents">
                           <span v-if="documents.length > 15">{{ documents.slice(0,15) }}...</span>
                           <span v-else style="
                          font-family: poppinsmedium;
                          font-size: 11px;
                          color: #000000;
                          text-transform: none;
                        ">
                            {{ documents }}
                          </span>
                            &nbsp;
                            <span
                              v-if="
                                documents.split('.').pop() == 'pdf' ||
                                documents.split('.').pop() == 'docx' ||
                                documents.split('.').pop() == 'mp4'
                              "
                            >
                              <a
                                target="_blank"
                                :href="baseURL + '/file/get/' + documents"
                              >
                                <v-btn dark rounded outlined x-small>
                                  View
                                </v-btn></a
                              ></span
                            >
                            <span v-else>
                              <v-btn
                                @click="(doc = documents), (docDialog = true)"
                                dark
                                rounded
                                outlined
                                x-small
                              >
                                View
                              </v-btn>
                            </span>
                          </span>
                        </v-chip>
                      </v-col>
                    </v-row>
                    <v-row class="justify-center">
                      <div class="mt-5">
                        <template>
                          <v-dialog v-model="dialog" max-width="600px">
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                v-on="on"
                                v-bind="attrs"
                                outlined
                                color="red"
                                >Remove Affiliator</v-btn
                              >
                            </template>
                            <v-card>
                              <v-card-title
                                >Are you sure you want to remove this
                                Affiliator?</v-card-title
                              >
                              <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="dialog = false"
                                  >Cancel</v-btn
                                >
                                <v-btn
                                  color="blue darken-1"
                                  text
                                  @click="deleteuser()"
                                  >OK</v-btn
                                >
                                <v-spacer></v-spacer>
                              </v-card-actions>
                            </v-card>
                          </v-dialog>
                        </template>
                      </div>
                      <div class="px-2 mt-5" v-if="user.status == 'Pending'">
                        <v-dialog v-model="approveDialog" max-width="600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-on="on"
                              v-bind="attrs"
                              outlined
                              color="green"
                              >Approve Affiliator</v-btn
                            >
                          </template>
                          <v-card>
                            <v-card-title
                              >Are you sure you want to approve this Affiliator?
                            </v-card-title>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="approveDialog = false"
                                >Cancel</v-btn
                              >
                              <v-btn
                                color="blue darken-1"
                                :disabled="!approve"
                                text
                                @click="approve(user._id)"
                                >OK</v-btn
                              >
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                      <div class="px-2 mt-5" v-if="user.status == 'Pending'">
                        <v-dialog v-model="rejectDialog" max-width="600px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              outlined
                              v-on="on"
                              v-bind="attrs"
                              color="red"
                              @click="rejectDialog = true"
                            >
                              Reject Affiliator
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-title class="headline grey lighten-2">
                              Reject Confirmation
                            </v-card-title>
                            <v-card-text>
                              <span style="font-size: 16px; font-weight: 600"
                                >Please confirm that you want to Remove this
                                Affiliator?
                              </span>
                            </v-card-text>
                            <v-divider></v-divider>
                            <v-flex pt-5 pa-5>
                              <v-text-field
                                v-model="reason"
                                label="Reason"
                                dense
                                outlined
                              ></v-text-field>
                            </v-flex>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="primary"
                                @click="rejectDialog = false"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="error"
                                class="mr-4"
                                @click="reject(item)"
                              >
                                Delete
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </div>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
            <v-dialog persistent v-model="docDialog" width="70%">
              <v-card>
                <v-toolbar dense dark>
                  <v-spacer></v-spacer>
                  <v-btn
                    right
                    icon
                    dark
                    @click="(doc = null), (docDialog = false)"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-title class="text-h5 grey lighten-2">
                  Document
                </v-card-title>
                <v-card-text v-if="doc">
                  <v-img :src="mediaURL + doc"></v-img>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    outlined
                    small
                    color="red"
                    text
                    @click="(docDialog = false), (doc = null)"
                  >
                    Close
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-card>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      showsnackbar: false,
      ServerError: false,
      user: [],
      photos: [],
      seller: [],
      subcat: [],
      productStatus: false,
      product: [],
      productArray: [],
      categoryArray: [],
      msg: null,
      widthOfCard: "250px",
      appLoading: false,
      dialog: false,
      userStatus: null,
      userRole: localStorage.getItem("role"),
      tab: null,
      text: "",
      reviewDialog: false,
      approveDialog: false,
      rejectDialog: false,
      reason: "",
      docs: [],
      doc: null,
      documents: "",
      docDialog: false,
      navItems: [
        { name: "Products", type: null },
        { name: "Offers", type: "Offer" },
        { name: "Deals", type: "Deals" },
      ],
      currentType: null,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      this.appLoading = true;
      axios({
        method: "GET",
        url: "/affliator/info",
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
        params: {
          id: this.$route.query.affiliateId,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            this.user = response.data.data;
            this.documents = this.user.affliator.portifolio;
            if (
              this.getFile_ext(this.documents) == "jpg" ||
              this.getFile_ext(this.documents) == "png" ||
              this.getFile_ext(this.documents) == "jpeg"
            )
              this.photos.push(this.documents);
            else this.docs.push(this.documents);
            // this.userStatus = this.user.status;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          // this.ServerError = true;
          console.log(err);
        });
    },
    getFile_ext(filename) {
      var ext;
      ext = filename.slice(
        (Math.max(0, filename.lastIndexOf(".")) || Infinity) + 1
      );
      return ext;
    },
    deleteuser() {
      axios({
        url: "/affliator/delete",
        method: "GET",
        params: {
          id: this.$route.query.affiliateId,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.dialog = false;
          this.appLoading = false;
          if (response.data.status == true) {
            this.msg = "Deleted Sucessfully";
            this.showsnackbar = true;
            this.$router.go(-1);
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    approve() {
      axios({
        method: "POST",
        url: "/affliator/approve",
        data: {
          id: this.$route.query.affiliateId,
          approve: true,
          commisionPercentage: Number(this.commisionPercentage),
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.approveDialog = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Approved";
            this.showsnackbar = true;
            // this.getData();
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    reject() {
      axios({
        method: "POST",
        url: "/affliator/approve",
        data: {
          id: this.$route.query.affiliateId,
          approve: false,
          reason: this.reason,
        },
        headers: {
          "x-auth-token": localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.rejectDialog = false;
          this.appLoading = false;
          if (response.data.status) {
            // this.getData();
            this.$router.go(-1);
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    edit(sid) {
      this.$router.push("/sellers/editSeller/" + sid);
    },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      var hours = dt.getHours();
      var minutes = dt.getMinutes();
      dt = dt.toString();
      var ampm = hours >= 12 ? "pm" : "am";
      hours = hours % 12;
      hours = hours ? hours : 12;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      var strTime =
        day +
        " " +
        dt.slice(4, 7) +
        " " +
        year +
        " " +
        hours +
        ":" +
        minutes +
        " " +
        ampm;
      return strTime;
    },
  },
};
</script>
<style scoped>
.item_title {
  font-weight: bold;
  font-size: 14px;
}
.item_value {
  font-size: 13px;
}
</style>
